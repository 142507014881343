<template>
  <div class="container" style="margin-top:-35px">
    <div class="box">
      <div class="columns">
        <div class="column">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <router-link to="/">Home</router-link>
              </li>
              <li>
                <router-link to="/classroom/inicio">Inicio</router-link>
              </li>
              <li class="is-active">
                <a href="#" aria-current="page">Tarea</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
    <div class="box">
      <div class="columns">
        <div class="column is-8">
          <h1 class="title is-4">{{ tarea.titulo }}</h1>
        </div>
        <div
          class="column is-4"
          v-if="tarea_alumno.tarea_realizada === 0 && tarea.estado === true"
        >
          <div class="is-pulled-right">
            <b-button
              v-if="respuesta.descripcion == null"
              type="is-primary"
              @click="responderTarea"
              :loading="loadingStore"
              rounded
              outlined
              >Responder</b-button
            >
            <b-button
              v-if="respuesta.descripcion"
              type="is-primary"
              :loading="loadingStore"
              rounded
              outlined
              @click="update_respuesta"
              >Editar Respuesta</b-button
            >
            <b-button
              v-if="respuesta.descripcion"
              type="is-primary"
              @click="entregarTarea"
              :loading="loadingStore"
              class="ml-2"
              rounded
              >Entregar Tarea
            </b-button>
          </div>
        </div>
        <div class="column is-4" v-if="tarea_alumno.tarea_realizada === 1">
          <h1 class="title is-5" v-if="tarea.estado === false">
            Tarea Cerrada
          </h1>
          <h1 class="title is-4">
            Entregada
            <b-icon pack="fas" icon="check" size="is-small" type="is-success">
            </b-icon>
          </h1>
        </div>
        <div class="column is-4" v-if="tarea_alumno.tarea_realizada === 2">
          <div class="is-pulled-right">
            <h1 class="title is-5" v-if="tarea.estado === false">
              Tarea Cerrada
            </h1>
            <h1 class="title is-4 has-text-success">
              Revisada
              <b-icon
                pack="fas"
                icon="check"
                size="is-small"
                type="is-success"
                class="ml-2"
              >
              </b-icon>
              <b-icon
                pack="fas"
                icon="check"
                size="is-small"
                type="is-success"
                class="ml-2"
              >
              </b-icon>
            </h1>
          </div>
        </div>
        <div class="column is-4" v-if="tarea_alumno.tarea_realizada === 3">
          <h1 class="title is-5" v-if="tarea.estado === false">
            Tarea Cerrada
          </h1>
          <h1 class="title is-4">
            Entregada Atrasada
            <b-icon pack="fas" icon="check" size="is-small" type="is-warning">
            </b-icon>
          </h1>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-3">
        <div class="box">
          <div class="columns">
            <div class="column is-3">
              <img
                :src="
                  '/assets/img/asignaturas/' + tarea.asignatura.imagen + '.svg'
                "
                :alt="tarea.asignatura.imagen"
                width="45"
              />
            </div>
            <div class="column is-9">
              <h1>{{ tarea.curso.nivel.nombre }} {{ tarea.curso.letra }}</h1>
              <h1>{{ tarea.asignatura.nombre }}</h1>
            </div>
          </div>
          <h1 class="title is-5">Docente</h1>
          <h1 class="subtitle is-5 mb-6">{{ tarea.user.name }}</h1>
          <h2 class="title is-5">Fechas de Inicio</h2>
          <h2 class="subtitle is-5 mb-6">
            {{ date_normal(tarea.created_at) }}
          </h2>
          <h3 class="title is-5">Fecha de Entrega</h3>
          <h3 class="subtitle is-5">{{ date_normal(tarea.vencimiento) }}</h3>
        </div>
      </div>
      <div class="column is-9">
        <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
          <div
            slot="trigger"
            slot-scope="props"
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">
              {{ tarea.titulo }}
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
          <div class="card-content">
            <div class="content">
              <span style="white-space: pre-line;">
                {{ tarea.descripcion }}
              </span>
            </div>
            <div class="content" v-if="tarea.archivo != null">
              <h3 class="title is-5">Archivos adjunto</h3>
              <div v-for="file in tarea.archivo" :key="file.id">
                <b-button @click="downloadFile(file)" :loading="loadingFile">{{
                  file | truncate(40, "...")
                }}</b-button>
              </div>
              <h3 class="title is-5">Descargar todos los archivos</h3>
              <b-button @click="downloadAsZip()" :loading="loadingFile"
                >ZIP</b-button
              >
            </div>
          </div>
        </b-collapse>
        <br />
        <div
          class="box"
          v-if="tarea_alumno.tarea_realizada === 0 && tarea.estado === true"
        >
          <h1 class="title is-5">Respuesta</h1>
          <b-input
            v-model="respuesta_tarea"
            ref="respuesta_tarea"
            type="textarea"
            minlength="10"
            maxlength="5000"
            placeholder="Escribe aqui tu respuesta "
          >
          </b-input>
          <form enctype="multipart/form-data">
            <div class="form-group">
              <h3 class="has-text-weight-bold">Subir un archivo</h3>
              <input
                id="subida"
                type="file"
                multiple
                drag-drop
                accept="audio/,video/,application/msword,
                 application/vnd.ms-excel, application/vnd.ms-powerpoint,
                 text/plain, application/pdf, image/*, .zip,.rar,.7zip"
                class="form-control"
                @change="fieldChange"                
              />
            </div>
          </form>
          <div class="tags">
            <span
              v-for="(file, index) in files"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFiles(index)"
              ></button>
            </span>
            <span
              v-for="(file, index) in archivos"
              :key="index"
              class="tag is-primary"
            >
              {{ file }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropArchivos(index)"
              ></button>
            </span>
          </div>
        </div>
        <div class="box" v-else>
          <h1 class="title is-5">Respuesta</h1>
          <div class="content">
            <span style="white-space: pre-line;">
              {{ respuesta_tarea }}
            </span>
          </div>
          <div class="content" v-if="respuesta.archivo != null">
            <h3 class="title is-5">Archivos adjunto</h3>
            <div v-for="file in archivos" :key="file.id">
              <b-button
                @click="downloadFileAlumno(file)"
                :loading="loadingFileAlumno"
                >{{ file }}</b-button
              >
            </div>
          </div>
        </div>

        <div class="box">
          <b-tabs v-model="activeTab" position="is-centered" type="is-boxed">
            <b-tab-item label="Comentarios Generales">
              <div class="columns">
                <div class="column is-10">
                  <b-input
                    rounded
                    v-model="inputcomentario"
                    placeholder="Añadir un comentario "
                  ></b-input>
                </div>
                <div class="column is-2">
                  <b-button
                    type="is-success is-rounded"
                    @click="enviar"
                    :loading="loadingStore"
                    >Enviar</b-button
                  >
                </div>
              </div>
              <div v-for="comentario in comentarios" :key="comentario.id">
                <div
                  class="columns"
                  v-if="
                    comentario.user.accesos_activo[0].rol.nombre === 'Alumno'
                  "
                >
                  <div class="column">
                    <h1>
                      <b-tag type="is-light">{{
                        comentario.user.accesos_activo[0].rol.nombre
                      }}</b-tag>
                      <router-link to="">{{
                        comentario.user.name
                      }}</router-link>
                      {{ date_comentario(comentario.creado) }}
                    </h1>
                    <h2>{{ comentario.comentario }}</h2>
                    <hr />
                  </div>
                </div>
                <div
                  class="columns"
                  v-if="
                    comentario.user.accesos_activo[0].rol.nombre ===
                      'Docente' ||
                      comentario.user.accesos_activo[0].rol.nombre ===
                        'Docente AEDUC'
                  "
                >
                  <div class="column ">
                    <h1>
                      <b-tag type="is-light">{{
                        comentario.user.accesos_activo[0].rol.nombre
                      }}</b-tag>
                      <router-link to="">{{
                        comentario.user.name
                      }}</router-link>
                      {{ date_comentario(comentario.creado) }}
                    </h1>
                    <h2>{{ comentario.comentario }}</h2>
                    <hr />
                  </div>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item label="Comentarios privados" icon="lock is-small">
              <div class="columns">
                <div class="column is-10">
                  <b-input
                    rounded
                    v-model="inputcomentarioprivado"
                    placeholder="Añadir un comentario privado "
                  ></b-input>
                </div>
                <div class="column is-2">
                  <b-button
                    type="is-success is-rounded"
                    @click="enviarPrivado(tarea.user.id)"
                    :loading="loadingStore"
                    >Enviar</b-button
                  >
                </div>
              </div>
              <div
                v-for="comentarioprivado in comentariosprivados"
                :key="comentarioprivado.id"
              >
                <div
                  class="columns"
                  v-if="
                    comentarioprivado.user.accesos_activo[0].rol.nombre ===
                      'Alumno'
                  "
                >
                  <div class="column ">
                    <h1>
                      <b-tag type="is-light">{{
                        comentarioprivado.user.accesos_activo[0].rol.nombre
                      }}</b-tag>
                      <router-link to="">{{
                        comentarioprivado.user.name
                      }}</router-link>
                      {{ date_comentario(comentarioprivado.creado) }}
                    </h1>
                    <h2>{{ comentarioprivado.comentario }}</h2>
                    <hr />
                  </div>
                </div>
                <div
                  class="columns"
                  v-if="
                    comentarioprivado.user.accesos_activo[0].rol.nombre ===
                      'Docente' ||
                      comentarioprivado.user.accesos_activo[0].rol.nombre ===
                        'Docente AEDUC'
                  "
                >
                  <div class="column ">
                    <h1>
                      <b-tag type="is-light">{{
                        comentarioprivado.user.accesos_activo[0].rol.nombre
                      }}</b-tag>
                      <router-link to="">{{
                        comentarioprivado.user.name
                      }}</router-link>
                      {{ date_comentario(comentarioprivado.creado) }}
                    </h1>
                    <h2>{{ comentarioprivado.comentario }}</h2>
                    <hr />
                  </div>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      tarea_id: this.$route.params.tarea_id,
      alumno_id: this.$route.params.alumno_id,
      isLoading: true,
      files: [],

      id: null,
      comentario: null,
      comentarios: null,
      comentariosprivados: null,
      inputcomentario: null,
      inputcomentarioprivado: null,
      activeTab: 0,
      loadingStore: false,
      loadingFile: false,
      loadingFileAlumno: false,
      respuesta_tarea: null,
      archivos: [],
      tarea_alumno: {
        tarea_realizada: null,
      },
      respuesta: {
        descripcion: null,
      },
      alumnos: {
        user: {
          name: null,
        },
      },
      tarea: {
        curso: {
          nivel: {
            nombre: null,
          },
        },
        asignatura: {
          nombre: null,
        },
        user: {
          name: null,
        },
      },
      show: true,
    };
  },

  methods: {
    fieldChange(e) {
      let selectedFiles = e.target.files;

      if (!selectedFiles.length) {
        return false;
      }
    
     const maxBytes = 20000000;
     const tamanioEnMb = maxBytes / 1000000;
      
    for (let i = 0; i < selectedFiles.length; i++) {
        if(selectedFiles[i].size > maxBytes){
            this.$buefy.toast.open({
              message: `El tamaño máximo por archivo es de ${tamanioEnMb} MB
                ${selectedFiles[i].name} supera el tamaño permitido.
            `,
              position: "is-bottom",
              type: "is-danger",
            });
            
      }else{
          this.files.push(selectedFiles[i]);
        }
        
    }
    document.getElementById("subida").value = "";
    },
    deleteDropFiles(index) {
      this.files.splice(index, 1);
    },
    deleteDropArchivos(index) {
      this.archivos.splice(index, 1);
    },
    date_normal: function(date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    date_comentario: function(date) {
      return moment(date, "YYYY-MM-DD, hh:mm a").format("DD-MM-YYYY, hh:mm a ");
    },
    downloadFile(file) {
      let self = this;
      let formData = new FormData();
      formData.append("tarea_id", this.tarea_id);
      formData.append("file", file);
      self.loadingFile = true;
      axios
        .post("/api/tareas/descargar/file", formData, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/*",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = file;
          link.click();
          self.loadingFile = false;
        });
    },
    downloadAsZip() {
      let self = this;
      let formData = new FormData();
      formData.append("tarea_id", this.tarea_id);

      self.loadingFile = true;
      axios
        .post("/api/tareas/descargar/zip", formData, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/*",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "archivos.zip";
          link.click();
          self.loadingFile = false;
        });
    },
    downloadFileAlumno(file) {
      let formData = new FormData();

      formData.append("tarea_id", this.tarea_id);
      formData.append("alumno_id", this.alumno_id);
      formData.append("respuesta_id", this.respuesta.id);
      formData.append("file", file);

      let self = this;
      self.loadingFileAlumno = true;
      axios
        .post("/api/tareasRespuestas/descargar", formData, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/*",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = file;
          link.click();
          self.loadingFileAlumno = false;
        });
    },
    update_respuesta() {
      let self = this;
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("respuesta_tarea", this.respuesta_tarea);

      for (let i = 0; i < this.archivos.length; i++) {
        formData.append("bdArchivos[]", this.archivos[i]);
      }

      for (let i = 0; i < this.files.length; i++) {
        formData.append("archivos[]", this.files[i]);
      }

      if (!this.respuesta_tarea) {
        this.$buefy.toast.open({
          message: `Debes responder la tarea`,
          position: "is-bottom",
          type: "is-danger",
        });
        this.$refs.respuesta_tarea.focus();

        return;
      }

      axios
        .post("/api/tareasRespuestas/update", formData)
        .then((response) => {
          self.loadingStore = false;
          this.$buefy.toast.open({
            message: response.data.message,
            type: "is-success",
          });
          //this.prueba = response.data;
          this.archivos = [];
          this.files = [];
          this.getRespuesta();
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: "no se pudo actualizar la tarea",
            position: "is-bottom",
            type: "is-danger",
          });
          self.loadingStore = false;
          throw error;
        });
    },
    entregarTarea() {
      this.$buefy.dialog.confirm({
        title: "Entregar tarea",
        message:
          "¿Estás seguro que deseas <b>Entrega</b> la tarea ?. No podras editar la tarea cuando la entreges, solo podras hacerlo hasta que el profesor decida devolverte la tarea.",
        confirmText: "Entregar tarea",
        type: "is-info",
        hasIcon: true,
        onConfirm: () => {
          let params = {
            params: this.$data,
          };
          this.isLoading = true;
          axios
            .post("/api/tareasAlumnos/entregar_tarea", params)
            .then((response) => {
              this.$buefy.toast.open({
                message: response.data.message,
                type: "is-success",
              });
              this.archivos = [];
              this.files = [];
              location.reload();
            })
            .catch((error) => {
              this.$buefy.toast.open({
                message: "No se pudo entregar la tarea",
                position: "is-bottom",
                type: "is-danger",
              });
              throw error;
            });
        },
      });
    },
    responderTarea() {
      let self = this;
      let formData = new FormData();
      formData.append("tarea_id", this.tarea_id);
      formData.append("alumno_id", this.alumno_id);
      formData.append("respuesta_tarea", this.respuesta_tarea);

      if (this.files != null) {
        this.files.forEach((file) => {
          formData.append("archivos[]", file);
        });
      }

      if (!this.respuesta_tarea) {
        this.$buefy.toast.open({
          message: `Debes responder la tarea`,
          position: "is-bottom",
          type: "is-danger",
        });
        this.$refs.respuesta_tarea.focus();

        return;
      }
      self.loadingStore = true;

      axios
        .post("/api/tareasRespuestas/store", formData)
        .then((response) => {
          self.loadingStore = false;
          this.$buefy.toast.open({
            message: response.data.message,
            type: "is-success",
          });
          //this.prueba = response.data;
          this.archivos = [];
          this.files = [];
          this.getRespuesta();
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: "no se pudo responder la tarea",
            position: "is-bottom",
            type: "is-danger",
          });
          self.loadingStore = false;
          throw error;
        });
    },
    getComentarios() {
      let params = {
        tarea_id: this.tarea_id,
      };
      axios
        .post("/api/tareasComentarios/list", params)
        .then((response) => {
          this.comentarios = response.data;
        })
        .catch((error) => {
          throw error;
        });
      this.inputcomentario = "";
    },

    getComentariosPrivados() {
      let params = {
        tarea_id: this.tarea_id,
        alumno_id: this.$route.params.alumno_id,
      };
      axios
        .post("/api/tareasComentariosPrivados/listComentariosProfesor", params)
        .then((response) => {
          this.comentariosprivados = response.data;
        })
        .catch((error) => {
          throw error;
        });
      this.inputcomentarioprivado = "";
    },
    getTarea() {
      let params = {
        tarea_id: this.tarea_id,
      };
      axios
        .post("/api/tareas/get", params)
        .then((response) => {
          this.tarea = response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    enviar() {
      if (!this.inputcomentario) {
        this.$buefy.toast.open({
          message: `Debes ingresar un comentario`,
          position: "is-bottom",
          type: "is-danger",
        });

        //this.$refs.inputcomentario.focus();
        return;
      }

      let params = {
        params: this.$data,
      };
      axios
        .post("/api/tareasComentarios/store", params)
        .then((response) => {
          this.$buefy.toast.open({
            message: response.data.message,
            type: "is-success",
          });
          this.getComentarios();
        })
        .catch((error) => {
          throw error;
        });
    },
    enviarPrivado(docente_id) {
      if (!this.inputcomentarioprivado) {
        this.$buefy.toast.open({
          message: `Debes ingresar un comentario`,
          position: "is-bottom",
          type: "is-danger",
        });

        this.$refs.inputcomentarioprivado.focus();
        return;
      }

      let params = {
        params: this.$data,
        alumno_id: this.$route.params.alumno_id,
        docente_id: docente_id,
      };

      axios
        .post("/api/tareasComentariosPrivados/store", params)
        .then((response) => {
          this.$buefy.toast.open({
            message: response.data.message,
            type: "is-success",
          });
          this.getComentariosPrivados();
        })
        .catch((error) => {
          throw error;
        });
    },
    getRespuesta() {
      let params = {
        tarea_id: this.tarea_id,
        alumno_id: this.$route.params.alumno_id,
      };
      axios
        .post("/api/tareasRespuestas/select", params)
        .then((response) => {
          let data = response.data;
          this.respuesta = data;
          this.respuesta_tarea = data.descripcion;
          this.archivos = data.archivo;
          this.id = data.id;
          this.isLoading = false;
        })
        .catch((error) => {
          throw error;
        });
    },
    tareaAlumno() {
      let params = {
        tarea_id: this.tarea_id,
        alumno_id: this.$route.params.alumno_id,
      };
      axios
        .post("/api/tareasAlumnos/select", params)
        .then((response) => {
          this.tarea_alumno = response.data;

          this.isLoading = false;
        })
        .catch((error) => {
          throw error;
        });
    },
    visto() {
      let params = {
        params: this.$data,
      };
      axios
        .post("/api/tareasComentariosPrivados/visto_alumno", params)
        .then(() => {})
        .catch((error) => {
          throw error;
        });
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  mounted() {
    this.getTarea();
    this.getComentarios();
    this.getComentariosPrivados();
    this.getRespuesta();
    this.tareaAlumno();
    this.visto();
  },
};
</script>
