import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import wizard from '../middleware/wizard';

import SinColegio from "../views/error/SinColegio.vue";
import NoEsEstudiante from "../views/error/NoEsEstudiante.vue";

import ClassroomInicio from "../views/classroom/Inicio.vue";
import EvaluaPendientes from "../views/evalua/Pendientes.vue";
import Tarea from "../views/classroom/Tarea.vue";
import Configuraciones from "../views/Configuraciones.vue";

import ResponderEvaluacion from "../views/evalua/ResponderEvaluacion.vue";
import ResumenEvaluacion from "../views/evalua/ResumenEvaluacion.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            middleware: wizard
        },
    },
   
    {
      path: "/configuraciones",
      name: "Configuraciones",
      component: Configuraciones,
      meta: {
        middleware: wizard
      },
    },
    {
      path: "/classroom/inicio",
      name: "ClassroomInicio",
      component: ClassroomInicio,
      meta: {
        middleware: wizard
      },
    },
    {
      path: "/evalua/pendientes",
      name: "EvaluaPendientes",
      component: EvaluaPendientes,
      meta: {
        middleware: wizard
      },
    },
    {
      path: "/tarea/:tarea_id/:alumno_id",
      name: "Tarea",
      component: Tarea,
      meta: {
        middleware: wizard
      },
    },
    {
      path: "/evalua/resolver/:asignacion/:total/:pregunta",
      name: "ResponderEvaluacion",
      component: ResponderEvaluacion,
      meta: {
        middleware: wizard
      },
    },
    {
      path: "/evalua/resumen/:asignacion/:total/:pregunta",
      name: "ResumenEvaluacion",
      component: ResumenEvaluacion,
      meta: {
        middleware: wizard
      },
    },
    {
        path: "/error/8005",
        name: "SinColegio",
        component: SinColegio,
        meta: {
          middleware: wizard
        },
    },
    {
        path: "/error/8006",
        name: "NoEsEstudiante",
        component: NoEsEstudiante,
        meta: {
          middleware: wizard
        },
    },
    {
        path: '/404',
        name: 'Notfound'
    },
    {
        path: '*',
        redirect: '/404'
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index,  1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
