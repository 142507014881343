<template>
<div>
    <b-navbar class="container">
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img src="/assets/img/logo-evalua.png" alt="Aeduc">
            </b-navbar-item>
        </template>
        <template slot="start">
            <b-navbar-item tag="router-link" to="/">
                Home
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/evalua/pendientes">
                Evalúa
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/classroom/inicio">
                Aula Virtual
            </b-navbar-item>            
            <!-- <b-navbar-item tag="router-link" to="/configuraciones">
                Configuraciones
            </b-navbar-item> -->
        </template>

        <template slot="end" v-if="authenticated">
            <b-dropdown aria-role="list">
                <button class="button is-info is-rounded mt-2" slot="trigger">
                    <span>
                        Notificaciones <span class="tag is-rounded" v-if="comentarios">
                            {{comentarios.length}}
                        </span>
                    </span>
                </button>
                <b-dropdown-item aria-role="listitem" v-for="comentario in comentarios" :key="comentario.id">
                    <router-link :to="'/tarea/' + comentario.tarea.id + '/'+ comentario.alumno_id" class="has-text-black">
                        <p><strong>{{comentario.user.name}}</strong></p>
                        <p>Te envió un mensaje privado.</p>
                        <p class="is-small is-size-7 has-text-primary">{{formatFecha(comentario.creado)}}</p>
                    </router-link>
                </b-dropdown-item>
            </b-dropdown>

            <b-navbar-dropdown :label="user.name">
                <b-navbar-item href="#" v-on:click="logout()" style="color: #363636">
                    <b-icon icon="sign-out-alt" pack="fas"></b-icon><span>Cerrar sesión</span>
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>
    </b-navbar>
    <section class="section">
        <router-view />
        <vue-progress-bar></vue-progress-bar>
    </section>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'
import axios from "axios";
import moment from 'moment';

export default {
     data(){
        return{
            comentarios:null
        }
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Home',
        // all titles will be injected into this template
        titleTemplate: '%s | AEDUC Evalúa'
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        })
    },

    methods: { 
        formatFecha(args) {
            let fecha = moment(args);
            fecha.locale('es');
            return fecha.fromNow();
        },
         notificaciones(){
            axios.post('/api/tareasComentariosPrivados/notificaciones_alumno').then((response) => {
                this.comentarios = response.data;
            }).catch((error) => {
                throw error;
            })
        },
        ...mapActions({
            signOutAction: 'auth/signOut'
        }),

        async logout() {
            await this.signOutAction()

            location.href = process.env.VUE_APP_LOGIN_URL
        }
    },
    mounted() {
        //  [App.vue specific] When App.vue is finish loading finish the progress bar
        this.$Progress.finish()
         this.notificaciones();
    },
    created() {
        //  [App.vue specific] When App.vue is first loaded start the progress bar
        this.$Progress.start()
        //  hook the progress bar to start before we move router-view
        this.$router.beforeEach((to, from, next) => {
            //  does the page we want to go to have a meta.progress object
            if (to.meta.progress !== undefined) {
                let meta = to.meta.progress
                // parse meta tags
                this.$Progress.parseMeta(meta)
            }
            //  start the progress bar
            this.$Progress.start()
            //  continue to next page
            next()
        })
        //  hook the progress bar to finish after we've finished moving router-view
        this.$router.afterEach(() => {
            //  finish the progress bar
            this.$Progress.finish()
        })
    },
    watch: {
        $route() {
        this.notificaciones();
        }
    }
}
</script>
