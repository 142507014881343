<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/evalua/pendientes">Evaluaciones pendientes</router-link>
                        </li>
                        <li>
                            <router-link :to="'/evalua/resolver/' + asignacion + '/' + total + '/' + pregunta">Resolver evaluación</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Resumen respuestas</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>

    <div class="box">
        <div class="columns">
            <div class="column">
                <h4 class="title is-4" v-if="informacion"><strong>Evaluación, {{informacion.prueba.prueba_tipo.nombre}} {{informacion.prueba.prueba_etapa.nombre}} "{{informacion.prueba.nombre}}" de {{informacion.asignatura.nombre}}, {{informacion.curso.nivel.nombre}}
                        {{informacion.curso.letra}}</strong>
                </h4>
            </div>
            <div class="column">
                <b-button rounded type="is-primary" class="is-pulled-right" @click="finalizar" :loading="loadingFinalizar">Finalizar evaluación</b-button>
            </div>
        </div>

        <div class="columns">
            <div class="column is-6" v-if="preguntas[0]">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="pregunta in preguntas[0]" v-bind:key="pregunta.id">
                            <td>{{pregunta.orden}}</td>
                            <td><span v-html="estado(pregunta.check)"></span></td>
                            <td>
                                <b-button v-if="!pregunta.check" type="is-primary is-small" outlined rounded tag="router-link" :to="'/evalua/resolver/' + asignacion + '/' + total + '/' + pregunta.orden">Contestar</b-button>
                                <b-button v-else type="is-light is-small" rounded tag="router-link" :to="'/evalua/resolver/' + asignacion + '/' + total + '/' + pregunta.orden">Revisar</b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="column is-6" v-if="preguntas[1]">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="pregunta in preguntas[1]" v-bind:key="pregunta.id">
                            <td>{{pregunta.orden}}</td>
                            <td><span v-html="estado(pregunta.check)"></span></td>
                            <td>
                                <b-button v-if="!pregunta.check" type="is-primary is-small" outlined rounded tag="router-link" :to="'/evalua/resolver/' + asignacion + '/' + total + '/' + pregunta.orden">Contestar</b-button>
                                <b-button v-else type="is-light is-small" rounded tag="router-link" :to="'/evalua/resolver/' + asignacion + '/' + total + '/' + pregunta.orden">Revisar</b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <b-loading v-model="isLoading"></b-loading>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'

export default {
    name: "ResumenEvaluacion",
    metaInfo: {
        title: 'Resumen respuestas'
    },
    components: {
        Colegios
    },
    data() {
        return {
            total: this.$route.params.total,
            asignacion: this.$route.params.asignacion,
            pregunta: this.$route.params.pregunta,
            informacion: null,
            preguntas: [],
            isLoading: true,
            loadingFinalizar: false
        }
    },
    methods: {
        finalizar() {
            let self = this
            self.loadingFinalizar = true
            axios.post('/api/asignaciones/finalizar', {
                    asignacion: this.$route.params.asignacion
                })
                .then((res) => {
                    if (res.data.status) {
                        return this.$router.push({
                            name: 'EvaluaPendientes'
                        });
                    }
                    self.loadingFinalizar = false
                }).catch((err) => {
                    self.loadingFinalizar = false
                    throw err
                })
        },
        estado(estado) {
            if (estado) {
                return '<span class="has-text-primary">Contestada</span>'
            }
            return 'Omitida'
        },
        getInformacionAsignacion() {
            axios.post('/api/asignaciones/informacion', {
                    asignacion: this.$route.params.asignacion
                })
                .then((res) => {
                    this.informacion = res.data
                }).catch((err) => {
                    throw err
                })
        },
        getPreguntas() {
            let self = this
            self.isLoading = true
            axios.post('/api/asignaciones/pregunta', {
                pregunta: this.$route.params.pregunta,
                asignacion: this.$route.params.asignacion
            }).then((res) => {
                let list = res.data.preguntas;

                const half = Math.ceil(list.length / 2);

                this.$set(this.preguntas, 0, list.splice(0, half))
                this.$set(this.preguntas, 1, list.splice(-half))

                self.isLoading = false
            }).catch((err) => {
                self.isLoading = false
                throw err
            })
        }
    },
    mounted() {
        this.getInformacionAsignacion()
        this.getPreguntas()
    }
}
</script>
