<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/evalua/pendientes">Evaluaciones pendientes</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Resolver evaluación</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <h4 class="title is-4" v-if="informacion"><strong>Evaluación, {{informacion.prueba.prueba_tipo.nombre}} {{informacion.prueba.prueba_etapa.nombre}} "{{informacion.prueba.nombre}}" de {{informacion.asignatura.nombre}}, {{informacion.curso.nivel.nombre}}
                        {{informacion.curso.letra}}</strong>
                </h4>
            </div>
            <div class="column">
                <b-button v-if="pregunta" tag="router-link" :to="'/evalua/resumen/' + asignacion + '/' + total + '/' + pregunta.orden" rounded type="is-primary" class="is-pulled-right">Resumen de respuestas</b-button>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <b-pagination :total="total" v-model="current" :range-before="rangeBefore" :range-after="rangeAfter" :order="order" :size="size" :simple="isSimple" :rounded="isRounded" :per-page="perPage" :icon-prev="prevIcon" :icon-next="nextIcon"
                    aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page">


                    <b-pagination-button slot-scope="props" :page="props.page" :id="`page${props.page.number}`" tag="router-link" :to="`/evalua/resolver/` + asignacion + `/` + total + `/${props.page.number}`"
                        v-bind:class="paginationOrder[props.page.number]">
                        {{ props.page.number }}
                    </b-pagination-button>

                    <b-pagination-button slot="previous" slot-scope="props" :page="props.page" tag="router-link" :to="`/evalua/resolver/4/80/${props.page.number}`" disabled style="display:none">
                    </b-pagination-button>

                    <b-pagination-button slot="next" slot-scope="props" :page="props.page" tag="router-link" :to="`/evalua/resolver/4/80/${props.page.number}`" disabled style="display:none">
                    </b-pagination-button>

                </b-pagination>
            </div>
        </div>

    </div>

    <div v-if="pregunta">
        <div class="box" v-if="!exists(pregunta.enunciado) && !exists(pregunta.texto)">

            <h4 v-if="exists(pregunta.pregunta)" class="subtitle is-inline-flex"><span v-katex:auto class="ml-5" v-html="renderKatex(pregunta.pregunta)"></span> </h4>

            <img v-if="exists(pregunta.pregunta_imagen)" class="image ml-4" :src="'https://evalua.s3.amazonaws.com/pruebas/' + pregunta.prueba.codigo + '/' + pregunta.pregunta_imagen + ''">

            <ul v-if="pregunta.respuestas">
                <li v-for="res in pregunta.respuestas" v-bind:key="res.id">

                    <div v-if="exists(res.respuesta)" class="is-inline-flex pt-4">
                        <b-button v-bind:class="respuestaButton[res.letra]" type="is-info" @click="responder(res.id)" outlined rounded>{{res.letra}}</b-button> <span v-katex:auto class="ml-4 mt-2"  v-html="renderKatex(res.respuesta)"></span>
                    </div>

                    <div else>
                        <div v-if="exists(res.respuesta_imagen)" class="is-inline-flex pt-4">
                            <b-button v-bind:class="respuestaButton[res.letra]" type="is-info" @click="responder(res.id)" outlined rounded>{{res.letra}}</b-button>

                            <img width="50%" class="image ml-4" :src="'https://evalua.s3.amazonaws.com/pruebas/' + pregunta.prueba.codigo + '/' + res.respuesta_imagen + ''">
                        </div>
                    </div>

                </li>
            </ul>
        </div>

        <div v-if="exists(pregunta.enunciado) || exists(pregunta.texto)">
            <div class="columns">
                <div class="column is-7" v-if="exists(pregunta.enunciado) || exists(pregunta.texto)">
                    <div class="box ">
                        <!-- <h4 v-if="exists(pregunta.enunciado)" class="subtitle"><span v-katex:auto class="ml-5" v-html="pregunta.enunciado"></span> </h4> -->
                        <p v-if="exists(pregunta.enunciado)">
                            <span v-katex:auto v-html="pregunta.enunciado"></span>
                        </p>

                        <img v-if="exists(pregunta.pregunta_imagen)" class="image ml-4" width="50%" :src="'https://evalua.s3.amazonaws.com/pruebas/' + pregunta.prueba.codigo + '/' + pregunta.pregunta_imagen + ''">

                        <p v-if="exists(pregunta.texto)">
                            <span v-katex:auto v-html="pregunta.texto"></span>
                        </p>

                    </div>
                </div>
                <div class="column ml-4">
                    <div class="box">
                        <h4 class="subtitle is-inline-flex"><span v-katex:auto class="ml-5" v-html="renderKatex(pregunta.pregunta)"></span> </h4>
                        <ul v-if="pregunta.respuestas">
                            <li v-for="res in pregunta.respuestas" v-bind:key="res.id">

                                <div v-if="exists(res.respuesta)" class="is-inline-flex pt-4">
                                    <b-button v-bind:class="respuestaButton[res.letra]" type="is-info" @click="responder(res.id)" outlined rounded>{{res.letra}}</b-button> <span v-katex:auto class="ml-4 mt-2"
                                        v-html="renderKatex(res.respuesta)"></span>
                                </div>

                                <div else>
                                    <div v-if="exists(res.respuesta_imagen)" class="is-inline-flex pt-4">
                                        <b-button :disabled="button_loading" v-bind:class="respuestaButton[res.letra]" type="is-info" @click="responder(res.id)" outlined rounded>{{res.letra}}</b-button>

                                        <img class="image ml-4" :src="'https://evalua.s3.amazonaws.com/pruebas/' + pregunta.prueba.codigo + '/' + res.respuesta_imagen + ''">
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="exists(pregunta.puntos)">
            <div class="columns">
                <div class="column is-12">
                    <div class="box">
                        <b-field>
                            <b-input v-model="respuestaTexto" maxlength="500" type="textarea" placeholder="Escribe tu respuesta"></b-input>
                        </b-field>
                        <ul v-if="pregunta.respuestas">
                            <li v-for="res in pregunta.respuestas" v-bind:key="res.id">
                                <b-button type="is-info" @click="responderTexto(res.id)" outlined rounded>Responder</b-button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <b-loading v-model="isLoading"></b-loading>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'

export default {
    name: "ResponderEvaluacion",
    metaInfo: {
        title: 'Responder Evaluación'
    },
    components: {
        Colegios
    },
    data() {
        return {
            button_loading:false,
            informacion: null,
            total: this.$route.params.total,
            asignacion: this.$route.params.asignacion,
            current: 1,
            perPage: 1,
            rangeBefore: 100,
            rangeAfter: 100,
            order: '',
            size: '',
            isSimple: false,
            isRounded: false,
            prevIcon: 'chevron-left',
            nextIcon: 'chevron-right',
            pregunta: null,
            isLoading: false,
            paginationOrder: [],
            respuestaButton: [],
            respuestaTexto: null
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    let self = this
                    self.isLoading = true
                    self.respuestaTexto = null
                    this.current = parseInt(newVal.params.pregunta)
                    if (this.current > self.total) {
                        return this.$router.push({
                            name: 'ResumenEvaluacion',
                            params: {
                                asignacion: this.$route.params.asignacion,
                                pregunta: this.current - 1,
                                total: this.$route.params.total
                            }
                        });
                    }
                    axios.post('/api/asignaciones/pregunta', {
                        pregunta: newVal.params.pregunta,
                        asignacion: newVal.params.asignacion
                    }).then((res) => {
                        this.pregunta = res.data.pregunta
                        this.respuestaButton = []
                        this.pregunta.respuestas.forEach((item) => {
                            if (item.check) {
                                this.$set(this.respuestaButton, item.letra, ' has-background-info has-text-white ');
                                this.respuestaTexto = item.resp
                            }
                        })
                        res.data.preguntas.forEach((item) => {
                            if (item.check) {
                                this.$set(this.paginationOrder, item.orden, ' has-background-primary has-text-white ');
                            }
                        })
                        self.isLoading = false
                    }).catch((err) => {
                        self.isLoading = false
                        throw err
                    })
                }
            },
        },
    },
    methods: {
        renderKatex(args) {
            // args = args.replace("$", "\\(");
            // args = args.replace("$", "\\)");
            return args
        },
        responderTexto(id) {

            if (!this.respuestaTexto) {
                return this.$buefy.toast.open({
                    message: 'Debes responder algo para guardar tu respuesta',
                    type: 'is-danger'
                })
            }

            let pregunta = this.pregunta.id;
            axios.post('/api/asignaciones/responder/texto', {
                    asignacion: this.$route.params.asignacion,
                    respuesta: id,
                    respuestaTexto: this.respuestaTexto,
                    total: this.$route.params.total,
                    pregunta: pregunta
                })
                .then((res) => {
                    if (res.data.status) {
                        this.respuestaButton = []
                        this.respuestaTexto = null
                        return this.$router.push({
                            name: 'ResponderEvaluacion',
                            params: {
                                asignacion: this.$route.params.asignacion,
                                pregunta: this.pregunta.orden + 1,
                                total: this.$route.params.total
                            }
                        });
                    } else {
                        return this.$buefy.toast.open({
                            message: 'Debes responder algo para guardar tu respuesta',
                            type: 'is-danger'
                        })
                    }
                }).catch((err) => {
                    throw err
                })
        },
        responder(id) {

            let pregunta = this.pregunta.id;
            this.isLoading =  true,
            this.button_loading = true
            axios.post('/api/asignaciones/responder', {
                    asignacion: this.$route.params.asignacion,
                    respuesta: id,
                    total: this.$route.params.total,
                    pregunta: pregunta
                })
                .then(() => {
                    this.respuestaButton = []
                        this.button_loading = false,
                        this.isLoading =  false

                    return this.$router.push({
                        name: 'ResponderEvaluacion',
                        params: {
                            asignacion: this.$route.params.asignacion,
                            pregunta: this.pregunta.orden + 1,
                            total: this.$route.params.total
                        }
                    });
                }).catch((err) => {
                    throw err
                })
        },
        getInformacionAsignacion() {
            axios.post('/api/asignaciones/informacion', {
                    asignacion: this.$route.params.asignacion
                })
                .then((res) => {
                    this.informacion = res.data
                }).catch((err) => {
                    throw err
                })
        },
        exists: function(string) {
            if (string == "NO") {
                return false;
            }
            if (string == "<p>NO</p>") {
                return false;
            }
            if (string == "null") {
                return false;
            }
            if (string == "") {
                return false;
            }
            if (string == null) {
                return false;
            }
            return true;
        }
    },
    mounted() {
        this.getInformacionAsignacion()
    }
}
</script>
<style scoped>
.ml-5 >>> p {
    display: inline;
}
</style>
