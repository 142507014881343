<template>
  <div class="container" style="margin-top:-35px">
    <div class="box">
      <div class="columns">
        <div class="column is-9">
          <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
            <ul>
              <li class="is-active">
                <a href="#" aria-current="page">Home</a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="column is-3">
          <Colegios />
        </div>
      </div>
    </div>

    <div class="box">
      <h1 class="title has-text-weight-semibold">Ambiente alumno</h1>


      <div class="columns">
        <div class="column is-2 has-text-centered">
          <router-link to="/evalua/pendientes">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Pruebas Pendientes
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  <div class>
                    {{ pruebaspendiente.length }}
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column is-2 has-text-centered" v-if="acceso.colegio.classroom">
          <div id="router" @click="selectedTarea(0)">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Tareas Pendientes
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  <div class>
                    {{ tareaspendiente.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-2 has-text-centered" v-if="acceso.colegio.classroom">
          <div id="router" @click="selectedTarea(1)">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Tareas Entregadas
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  <div class>
                    {{ tareasentregada.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-2 has-text-centered" v-if="acceso.colegio.classroom">
          <div id="router" @click="selectedTarea(2)">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Tareas Finalizadas
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  <div class>
                    {{ tareasfinalizada.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Colegios from "../components/Colegios.vue";
import axios from "axios";
import { mapGetters } from 'vuex'


export default {
  name: "Home",
  metaInfo: {
    title: "Home",
  },
  components: {
    Colegios,
  },
    computed: {
      ...mapGetters({
          authenticated: 'auth/authenticated',
          user: 'auth/user',
          acceso: 'auth/acceso'
      })
  },

  data() {
    return {
      tareaspendiente: [],
      tareasentregada: [],
      tareasfinalizada: [],
      pruebaspendiente: [],
      error: [],

      isLoading: false,
      name: null,
      email: null,
      rut: null,
      phone: null,
      id: null,
      password: null,
      password2:null,
      colegios: []
    };
  },
  methods: {
    getEvaluacionesPendientes() {
      axios
        .get("/api/asignaciones/alumno/pendientes")
        .then((res) => {
          this.pruebaspendiente = res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    TareasPendiente() {
      this.isLoading = true;
      const params = [`asignatura=${"null"}`].join("&");
      axios
        .get(`/api/tareas/tareas_pendiente?${params}`)
        .then((response) => {
          this.tareaspendiente = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          throw error;
        });
    },
    TareasEntregada() {
      this.isLoading = true;
      const params = [`asignatura=${"null"}`].join("&");
      axios
        .get(`/api/tareas/tareas_entregada?${params}`)
        .then((response) => {
          this.tareasentregada = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          throw error;
        });
    },
    TareasFinalizada() {
      this.isLoading = true;
      const params = [`asignatura=${"null"}`].join("&");
      axios
        .get(`/api/tareas/tareas_finalizada?${params}`)
        .then((response) => {
          this.tareasfinalizada = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          throw error;
        });
    },
    getAsignaturas() {
      axios
        .get("/api/asignaturas/select")
        .then((response) => {
          this.asignaturas = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          throw error;
        });
    },
    selectedTarea(item) {
      return this.$router.push({
        name: "ClassroomInicio",
        params: {
          value: item,
        },
      });
    },

    getUsuario() {
      axios.get("/api/usuarios/usuario")
        .then((response) => {
          let data = response.data;
          console.log(data);
          this.name = data.name;
          this.email = data.email;
          this.rut = data.rut;
          this.phone = data.phone;
          this.id = data.id;
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: "No se pudo encontrar el usuario",
            position: "is-bottom",
            type: "is-danger",
          });
          throw error;
        });
    },
    
  },

  mounted() {
    this.getEvaluacionesPendientes();
    this.TareasPendiente();
    this.TareasEntregada();
    this.TareasFinalizada();
    this.getUsuario();
  },
};
</script>
<style scoped>
.card:hover {
  background: rgba(0, 150, 245, 0.3);
  cursor: pointer;
}
</style>
