<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column is-9">
                <nav class="breadcrumb mt-2" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Evaluaciones pendientes</a></li>
                    </ul>
                </nav>
            </div>
            <div class="column is-3">
                <Colegios />
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns is-multiline">
            <!-- {{loadingButton}} -->
            <div class="column is-4" v-for="pendiente in pendientes" v-bind:key="pendiente.id">
                <article :class="'message is-' + pendiente.asignacion.asignatura.imagen">
                    <div class="message-body">
                        <div class="media">
                            <div class="media-left">
                                <figure class="image is-32x32">
                                    <img :src="'/assets/img/asignaturas/' + pendiente.asignacion.asignatura.imagen + '.svg'" :alt="pendiente.asignacion.asignatura.imagen">
                                </figure>
                            </div>
                            <div class="media-content">
                                <h1 class="title is-5">{{formatDate(pendiente.created_at)}}</h1>
                            </div>
                        </div>

                        <h4 class="mt-5 title is-4" style="height:100px"><strong>Evaluación,  {{pendiente.asignacion.prueba.prueba_tipo.nombre}} {{pendiente.asignacion.prueba.prueba_etapa.nombre}} "{{pendiente.asignacion.prueba.nombre}}" {{pendiente.asignacion.asignatura.nombre}}</strong>
                        </h4>

                        
                        <p class="mt-5">
                            Profesor: {{pendiente.asignacion.user.name}}
                        </p>
                       
                        <p>Curso: {{pendiente.asignacion.curso.nivel.nombre}} {{pendiente.asignacion.curso.letra}}</p>

                        <div class="has-text-centered">
                            <b-button class="mt-5" :loading="loadingButton[pendiente.asignacion.id]" rounded :type="'is-' + pendiente.asignacion.asignatura.imagen" @click="comenzar(pendiente.asignacion.id)">Comenzar</b-button>
                        </div>

                    </div>
                </article>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Colegios from '../../components/Colegios.vue'
import axios from 'axios'
import moment from 'moment'

export default {
    name: "EvaluaPendientes",
    metaInfo: {
        title: 'Evaluaciones pendientes'
    },
    components: {
        Colegios
    },
    data() {
        return {
            pendientes: [],
            loadingButton: []
        }
    },
    methods: {
        comenzar(asignacion) {

            this.$set(this.loadingButton, asignacion, true)

            axios.post('/api/asignaciones/alumno/evaluacion/comenzar', {
                asignacion: asignacion
            }).then((res) => {
                if (res.data.status) {
                    return this.$router.push({
                        name: 'ResponderEvaluacion',
                        params: {
                            asignacion: asignacion,
                            pregunta: res.data.pregunta,
                            total: res.data.total
                        }
                    });
                }
            }).catch((err) => {
                this.$buefy.toast.open({
                    message: err,
                    type: 'is-danger'
                })
                this.$set(this.loadingButton, asignacion, false)
                throw err
            })
        },
        formatDate(fecha) {
            return moment(fecha).locale('es').format('LL');
        },
        getEvaluacionesPendientes() {
            axios.get('/api/asignaciones/alumno/pendientes').then((res) => {
                this.pendientes = res.data
                res.data.forEach((item) => {
                    this.$set(this.loadingButton, item.asignacion.id, false)
                })
            }).catch((err) => {
                throw err
            })
        }
    },
    mounted() {
        this.getEvaluacionesPendientes()
    }
}
</script>
