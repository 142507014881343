<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Configuraciones</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns">
            <div class="column">
                <!-- <pre>
                    {{user}}
                </pre> -->
                 <b-field label="Nombre">
                    <b-input v-model='name' ref="name">                                           
                    </b-input>
                </b-field>
                 <b-field label="Email">             
                    <b-input                        
                        maxlength="30"
                        v-model='email'  ref="email">
                    </b-input>
                </b-field>
                <b-field label="Password">
                     (si no desea cambiar su contraseña solo deje este campo vacio)
                    <b-input type="password"
                        password-reveal
                        v-model="password">
                    </b-input>
                </b-field>
                <b-button class="is-info" @click="update_usuario">Guardar</b-button>
            </div>
            <div class="column">
                <b-field label="Rut">                   
                    <b-input v-model="rut" ref="rut">                        
                    </b-input>
                </b-field>
                <b-field label="Telefono">
                    <b-input v-model='telefono' ref="telefono"
                     maxlength="9">                        
                    </b-input>
                </b-field>
            </div>

        </div>
    </div> 
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
 
</div>
</template>
<script>

import axios from "axios";

export default {

    data() {
        return {
            isLoading: false,
            name: null,
            email:null,
            rut:null,
            phone:null,
            password:null,
            id:null
        }
    },

    methods: {
        update_usuario(){
            let params = {
                params: this.$data
            }
             axios.post('/api/usuarios/update' , params)
                .then(response => {
                    self.loadingStore = false;
                    if (response.data.status) {
                        this.$buefy.toast.open({
                            message: response.data.message,
                            type: 'is-success'
                        })
                    } else {
                        this.$buefy.toast.open({
                            message: response.data.message,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    }
                    this.getUsuario();
                })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'No se pudo actualizar el usuario',
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                    self.loadingStore = false;
                    throw error
                });
        },      
        getUsuario(){
           
            axios.get('/api/usuarios/usuario')
                .then(response => {
                    let data = response.data
                    console.log(data);
                    this.name = data.name;
                    this.email = data.email;
                    this.rut = data.rut;
                    this.phone = data.phone;
                    this.id = data.id
                   
                })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'No se pudo encontrar el usuario',
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                    throw error
                });
            
         },

    },
    mounted() {
        this.getUsuario();
    }

}
</script>
