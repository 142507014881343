<template>
<div class="container" style="margin-top:-35px">
    <div class="box">
        <div class="columns">
            <div class="column">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Inicio</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="box">
        <h1 class="title has-text-weight-semibold ">Hola, {{user.name}}</h1>

        <b-tabs position="is-centered" class="block" v-model="tabs">
            <b-tab-item label="Tareas Pendientes">
                <div class="columns">
                    <div class="column">
                        <b-select v-model="asignatura_id_pendiente" placeholder="Asignatura">
                            <option value="null">
                                Todas las Asignaturas
                            </option>
                            <option :value="asignatura.id" v-for="asignatura in asignaturas" :key="asignatura.id">
                                {{asignatura.nombre}}
                            </option>
                        </b-select>
                    </div>
                </div>


                <div class="columns is-multiline">
                    <div class="column is-3" v-for="tareapendiente in tareaspendiente" v-bind:key="tareapendiente.id">
                        <article :class="'message is-' + tareapendiente.tarea.asignatura.imagen">
                            <div class="message-body">
                                <div>
                                    <h1 class="mb-2">Fecha de entrega</h1>
                                </div>
                                <div class="columns has-text-right">
                                    <div class="column">
                                        <h1 class="title is-1">{{dia_numero(tareapendiente.tarea.vencimiento)}}</h1>
                                    </div>
                                    <div class="column is-5 has-text-left">
                                        <h1 class="title is-6">{{dia_escrito(tareapendiente.tarea.vencimiento)}}</h1>
                                        <h2 class="subtitle is-6">{{mes_año_escrito(tareapendiente.tarea.vencimiento)}}</h2>
                                    </div>
                                    <div class="column is-3">
                                        <img :src="'/assets/img/asignaturas/' + tareapendiente.tarea.asignatura.imagen + '.svg'" :alt="tareapendiente.tarea.asignatura.imagen" width="32">
                                    </div>

                                </div>
                                <div>
                                    <h1 class="title is-6">{{tareapendiente.tarea.asignatura.nombre}}</h1>
                                    <p>{{tareapendiente.tarea.titulo | truncate(50)}}</p>
                                </div>
                                <div class="has-text-centered " style="padding-top:20px;">
                                    <b-button type="is-primary is-rounded" tag="router-link" :to="'/tarea/' + tareapendiente.tarea.id + '/'+ tareapendiente.user.id">Ver Tarea</b-button>
                                </div>
                            </div>

                        </article>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Tareas Entregadas" id="TE">
                <div class="columns">
                    <div class="column">
                        <b-select v-model="asignatura_id_entregadas" placeholder="Asignatura">
                            <option value="null">
                                Todas las Asignaturas
                            </option>
                            <option :value="asignatura.id" v-for="asignatura in asignaturas" :key="asignatura.id">
                                {{asignatura.nombre}}
                            </option>
                        </b-select>
                    </div>
                </div>
                <div class="columns is-multiline">
                    <div class="column is-3" v-for="tareaentregada in tareasentregada" v-bind:key="tareaentregada.id">
                        <article :class="'message is-' + tareaentregada.tarea.asignatura.imagen">
                            <div class="message-body">

                                <div>
                                    <h1 class="mb-2">Fecha de entrega</h1>
                                </div>
                                <div class="columns has-text-right">
                                    <div class="column">
                                        <h1 class="title is-1">{{dia_numero(tareaentregada.tarea.vencimiento)}}</h1>
                                    </div>
                                    <div class="column is-5 has-text-left">
                                        <h1 class="title is-6">{{dia_escrito(tareaentregada.tarea.vencimiento)}}</h1>
                                        <h2 class="subtitle is-6">{{mes_año_escrito(tareaentregada.tarea.vencimiento)}}</h2>
                                    </div>
                                    <div class="column is-3">
                                        <img :src="'/assets/img/asignaturas/' + tareaentregada.tarea.asignatura.imagen + '.svg'" :alt="tareaentregada.tarea.asignatura.imagen" width="32">
                                    </div>

                                </div>
                                <div>
                                    <h1 class="title is-6">{{tareaentregada.tarea.asignatura.nombre}}</h1>
                                    <p>{{tareaentregada.tarea.titulo | truncate(50)}}</p>
                                </div>
                                <div class="has-text-centered " style="padding-top:20px;">
                                    <b-button type="is-primary is-rounded" tag="router-link" :to="'/tarea/' + tareaentregada.tarea.id + '/'+ tareaentregada.user.id">Ver Tarea</b-button>
                                </div>
                            </div>

                        </article>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Tareas Finalizadas" id="TF">
                <div class="columns">
                    <div class="column">
                        <b-select v-model="asignatura_id_finalizada" placeholder="Asignatura">
                            <option value="null">
                                Todas las Asignaturas
                            </option>
                            <option :value="asignatura.id" v-for="asignatura in asignaturas" :key="asignatura.id">
                                {{asignatura.nombre}}
                            </option>
                        </b-select>
                    </div>
                </div>
                <div class="columns is-multiline">
                    <div class="column is-3" v-for="tareafinalizada in tareasfinalizada" v-bind:key="tareafinalizada.id">
                        <article :class="'message is-' + tareafinalizada.tarea.asignatura.imagen">
                            <div class="message-body">

                                <div>
                                    <h1 class="mb-2">Fecha de entrega</h1>
                                </div>
                                <div class="columns has-text-right">
                                    <div class="column">
                                        <h1 class="title is-1">{{dia_numero(tareafinalizada.tarea.vencimiento)}}</h1>
                                    </div>
                                    <div class="column is-5 has-text-left">
                                        <h1 class="title is-6">{{dia_escrito(tareafinalizada.tarea.vencimiento)}}</h1>
                                        <h2 class="subtitle is-6">{{mes_año_escrito(tareafinalizada.tarea.vencimiento)}}</h2>
                                    </div>
                                    <div class="column is-3">
                                        <img :src="'/assets/img/asignaturas/' + tareafinalizada.tarea.asignatura.imagen + '.svg'" :alt="tareafinalizada.tarea.asignatura.imagen" width="32">
                                    </div>

                                </div>
                                <div>
                                    <h1 class="title is-6">{{tareafinalizada.tarea.asignatura.nombre}}</h1>
                                    <p>{{tareafinalizada.tarea.titulo | truncate(50)}}</p>
                                </div>
                                <div class="has-text-centered " style="padding-top:20px;">
                                    <b-button type="is-primary is-rounded" tag="router-link" :to="'/tarea/' + tareafinalizada.tarea.id + '/'+ tareafinalizada.user.id">Ver Tarea</b-button>
                                </div>
                            </div>

                        </article>
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</div>
</template>
<script>
import axios from "axios"
import {
    mapGetters
} from 'vuex'
import moment from 'moment';


export default {
    data() {
        return {
            isLoading: false,
            isFullPage: true,
            tareaspendiente: [],
            tareapendiente: {
                tarea: {
                    vencimiento: null,
                    asigantura: {

                    }
                }
            },
            tareasentregada: [],
            tareaentregada: {
                tarea: {
                    vencimiento: null,
                    asigantura: {

                    }
                }
            },
            tareasfinalizada: [],
            tareafinalizada: {
                tarea: {
                    vencimiento: null,
                    asigantura: {

                    }
                }
            },
            asignaturas: [],
            //isLoading: true,
            asignatura_id_pendiente: null,
            asignatura_id_finalizada: null,
            asignatura_id_entregadas: null,
            tabs: null
        }
    },
    watch: {
        asignatura_id_pendiente() {
            this.TareasPendiente()
        },
        asignatura_id_finalizada() {
            this.TareasFinalizada()
        },
        asignatura_id_entregadas() {
            this.TareasEntregada()
        }
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        })
    },
    methods: {
        dia_numero: function(date) {
            return moment(date, 'YY-MM-DD').format('DD')
        },
        dia_escrito: function(date) {
            return moment(date, 'YY-MM-DD').locale('es').format('dddd').toLowerCase().replace(/\b[a-z]/g, function(letra) {
                return letra.toUpperCase();
            });
        },
        mes_año_escrito: function(date) {
            return moment(date, 'YY-MM-DD').locale('es').format('MMMM YYYY').toLowerCase().replace(/\b[a-z]/g, function(letra) {
                return letra.toUpperCase();
            });
        },

        TareasPendiente() {
            this.isLoading = true
            const params = [
                `asignatura=${this.asignatura_id_pendiente}`
            ].join('&')
            axios.get(`/api/tareas/tareas_pendiente?${params}`).then((response) => {
                this.tareaspendiente = response.data;
                this.isLoading = false;
            }).catch((error) => {
                throw error;
            })

        },
        TareasEntregada() {
            this.isLoading = true
            const params = [
                `asignatura=${this.asignatura_id_entregadas}`
            ].join('&')
            axios.get(`/api/tareas/tareas_entregada?${params}`).then((response) => {
                this.tareasentregada = response.data;
                this.isLoading = false;

            }).catch((error) => {
                throw error;
            })

        },
        TareasFinalizada() {
            this.isLoading = true
            const params = [
                `asignatura=${this.asignatura_id_finalizada}`
            ].join('&')
            axios.get(`/api/tareas/tareas_finalizada?${params}`).then((response) => {
                this.tareasfinalizada = response.data;
                this.isLoading = false;
            }).catch((error) => {
                throw error;
            })
        },
        getAsignaturas() {
            axios.get('/api/asignaturas/select').then((response) => {
                this.asignaturas = response.data;
                this.isLoading = false

            }).catch((error) => {
                throw error;
            })
        },

        selectedTab(params){
            this.tabs = params;
        },
        tab(){
            if(this.$route.params){
                this.selectedTab(this.$route.params.value);
            }
        }
    },
    filters: {
        truncate(value, length) {
            return value.length > length ?
                value.substr(0, length) + '...' :
                value
        }
    },
    mounted() {
        this.TareasPendiente();
        this.TareasFinalizada();
        this.TareasEntregada();
        this.getAsignaturas();
        this.tab();
                  
        
        
        

    }

}
</script>
