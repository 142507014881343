import Vue from "vue";
import VueProgressBar from 'vue-progressbar'
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

const options = {
    color: '#2496f5',
    failedColor: '#874b4b',
    thickness: '5px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
    autoRevert: true,
    location: 'top',
    inverse: false
}

Vue.use(VueProgressBar, options)
Vue.config.productionTip = false;

store.dispatch('auth/me').then((status) => {
    if (status) {
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount("#app")
    }
})

const form = document.getElementById('form');
const email = document.getElementById('email');
const password = document.getElementById('password');
const password2 = document.getElementById('password2');

form.addEventListener('guardar', e => {
	e.preventDefault();
	
	checkInputs();
});

function checkInputs() {

    const emailValue = email.value.trim();
	const passwordValue = password.value.trim();
	const password2Value = password2.value.trim();
	
	if(emailValue === '') {
		setErrorFor(email, 'El campo de e-mail no puede estar en blanco');
	} else if (!isEmail(emailValue)) {
		setErrorFor(email, 'No es un correo válido');
	} else {
		setSuccessFor(email);
	}
	
	if(passwordValue === '') {
		setErrorFor(password, 'El campo de password no puede estar en blanco');
	} else {
		setSuccessFor(password);
	}
	
	if(password2Value === '') {
		setErrorFor(password2, 'El campo de confirmación no puede estar en blanco');
	} else if(passwordValue !== password2Value) {
		setErrorFor(password2, 'Las contraeñas no coinciden');
	} else{
		setSuccessFor(password2);
    }
    
}

function setErrorFor(input, message) {
	const formControl = input.parentElement;
	const small = formControl.querySelector('small');
	formControl.className = 'form-control error';
	small.innerText = message;
}

function setSuccessFor(input) {
	const formControl = input.parentElement;
	formControl.className = 'form-control success';
}
	
function isEmail(email) {
	return /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}
